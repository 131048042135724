import {graphql, useStaticQuery} from 'gatsby';

const useFooterLinksData = () => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        termsAndCondition {
          SEO {
            ...SEO
          }
          intro {
            ...Intro
          }
          termsAndConditionsFile {
            url
            file {
              publicURL
            }
          }
        }
      }
    }
  `);

  return data.strapi;
};

export default useFooterLinksData;
