import React from 'react';
import useFooterLinksData from '../queries/useFooterLinksData';
import {Intro, SEO} from '../components/organisms';
import {Download} from '../components/organisms/blocks';
import {PageContent} from '../components/organisms/Layout';

const TermsAndConditions = () => {

  const {termsAndCondition} = useFooterLinksData();
  const {intro, termsAndConditionsFile} = (termsAndCondition || {});
  if (!intro || !termsAndConditionsFile) {
    return null;
  }

  return (
    <PageContent>
      <SEO
        title={termsAndCondition.SEO?.title}
        description={termsAndCondition.SEO?.description}
        meta={[
          {
            name: 'keywords',
            content: termsAndCondition.SEO?.keywords,
          }
        ]}
      />
      <Intro intro={intro} />
      <Download
        mainTitle="Download"
        startingColumn={2}
        files={[{
          file: termsAndConditionsFile,
          displayName: 'Terms and conditions of purchase',
          interaction: 'download'
        }]}
      />
    </PageContent>
  );
};

export default TermsAndConditions;
